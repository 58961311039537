import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExistingCustomer = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  const [bookingCount, setBookingCount] = useState(0);

  function convertPointToDollar(basePoint, baseAmount, availablePoints) {
    if (availablePoints > 0) {
      return (
        (parseFloat(availablePoints) / parseFloat(basePoint)) *
        parseFloat(baseAmount)
      ).toFixed(2);
    } else {
      return 0;
    }
  }

  const goToBookServiceFunc = () => {
    navigate("/pick_services/" + props.customerResponse.id);
  };

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  const checkinBtnOnClick = async () => {
    let bookingIds = props.customerResponse.bookings.map((item) => item.id);

    const formData = {
      booking_ids: bookingIds,
    };

    await axios
      .post(props.apiURLNext + "customer_checkin", formData)
      .then((response) => {
        //if success trigger reload customerResponse

        toast.success("The booking was checked-in successfully!", {
          position: "bottom-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setReloadCustomer(true);
        window.location.href = "/final_screen";
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  const noBookingCheckIn = () => {
    if (bookingCount <= 0) {
      props.checkInCustomerNoBooking(props.customerResponse.id);
    } else {
      window.location.href = "/final_screen";
    }
  };

  useEffect(() => {
    if (props.reloadCustomer) {
      props.processCustomerPhoneInput(params.phoneNumber);
    }
  }, [props.reloadCustomer]);

  return (
    <div className="appContainer__main__existCustomer">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h5"
        gutterBottom
        component="div"
      >
        Welcome back, {titleCase(props.customerResponse.first_name)}
      </Typography>
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h6"
        gutterBottom
        component="div"
      >
        {props.store.loyalty_convert_point > 0 ? (
          <>
            You have {props.customerResponse.redeemed_points} points ($
            {convertPointToDollar(
              1000,
              props.store.loyalty_convert_point,
              props.customerResponse.redeemed_points
            )}
            ) available to redeem.
          </>
        ) : (
          ""
        )}
      </Typography>
      {props.customerResponse.bookings.length > 0 ? (
        <div>
          <Typography
            style={{ textAlign: "center" }}
            mt={3}
            variant="h6"
            gutterBottom
            component="div"
          >
            You have {props.customerResponse.bookings.length} booking
            {props.customerResponse.bookings.length > 1 ? "s" : ""} with us
            today.
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            mb={3}
            variant="subtitle"
            gutterBottom
            component="div"
          >
            Please tap on Check-in button
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Booking #</TableCell>
                  <TableCell align="right">Service</TableCell>
                  <TableCell align="right">Start</TableCell>
                  <TableCell align="right">End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.customerResponse.bookings.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.booking_reference}
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) =>
                                bookingDetailItem.service.name
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) =>
                                bookingDetailItem.start_time
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                    <TableCell align="right">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: row.booking_details
                            .map(
                              (bookingDetailItem) => bookingDetailItem.end_time
                            )
                            .join("<br>"),
                        }}
                      ></div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div>
          <Typography
            style={{ textAlign: "center" }}
            mt={3}
            variant="h6"
            gutterBottom
            component="div"
          >
            Please select options below to proceed.
          </Typography>
        </div>
      )}
      {props.customerResponse.bookings.length > 0 ? (
        <ButtonGroup
          variant="contained"
          style={{
            margin: "15px 0",
            display: "flex",
            flexFlow: "column",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <Button
            color="success"
            size="large"
            onClick={() => {
              checkinBtnOnClick();
              setBookingCount(bookingCount + 1);
            }}
            endIcon={<CheckCircleOutlineIcon />}
          >
            Check-in
          </Button>
          <Button
            color="error"
            size="large"
            onClick={(e) => {
              window.location.href = "/final_screen";
            }}
            endIcon={<NavigateNextIcon />}
          >
            Go Back
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          variant="contained"
          style={{
            margin: "15px 0",
            display: "flex",
            flexFlow: "column",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <Button
            className="btn-success"
            size="large"
            onClick={(e) => goToBookServiceFunc()}
            endIcon={<ArrowForwardIosIcon />}
          >
            Book Services
          </Button>
          <Button
            color="error"
            size="large"
            onClick={(e) => noBookingCheckIn()}
            endIcon={<NavigateNextIcon />}
          >
            Decide Later
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default ExistingCustomer;
